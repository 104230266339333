

import contract from './contracts/Contract.json';



import * as buffer from "buffer";
import { useEffect, useState } from 'react';
import './App.css';

import { ethers } from 'ethers';
import Select from "react-select";
import swal from 'sweetalert';
import {Buffer} from 'buffer';
import Typewriter from 'typewriter-effect/dist/core';
import { validate, getAddressInfo } from 'bitcoin-address-validation';


const keccak256 = require("keccak256");
const { MerkleTree } = require("merkletreejs");
const Web3 = require("web3");
const web3 = new Web3();


function amt(qty) {
return web3.eth.abi.encodeParameter("uint256", qty)
}


window.Buffer = buffer.Buffer;








const contractAddress = "0x2290995bB9C481306F83Bd8f549D9F1C41357444";
const abi = contract.abi;


function App() {


  const [currentAccount, setCurrentAccount] = useState(null);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists.")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
	  
	   document.getElementById("connected").innerHTML =
        " <font color='#36b500'>•</font> 0x..." + account.slice(32);
	  
	  
	  
    } else {
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }




























  var upgradeNftHandler = async () => {

	  
    try {
      var { ethereum } = window;
	  
 



      if (ethereum) {
        var provider = new ethers.providers.Web3Provider(ethereum);
        var signer = provider.getSigner();
        var nftContract = new ethers.Contract(contractAddress, abi, signer);

		

		let textBoxForCombination = document.getElementById("tokensToUpgradeId").value
		
		textBoxForCombination = textBoxForCombination.replace(/\s/g, '')
		let tokenArray = textBoxForCombination.split(',')
		
		console.log(tokenArray)
		
		if (tokenArray.length == 1) {
			


        var provider = new ethers.providers.Web3Provider(ethereum);
        var signer = provider.getSigner();
        var nftContract = new ethers.Contract(contractAddress, abi, signer);
		
	
		
		let tokenCheck = await nftContract.levelsEligibleForUpgrade(Number(tokenArray[0]))
		console.log(Number(tokenCheck))
		if (Number(tokenCheck) == 0) {
			swal("Too early", "This token ID has not been held long enough to be upgraded.", "error");
			return
		} else {
			swal("Upgrade Available", "This token ID can be upgraded to gain an extra " + tokenCheck + " level(s).", "success");
		}
			
			
			
			
			
		let estimation = await nftContract.estimateGas.upgradeToken(Number(tokenArray[0]))
		
		console.log(estimation)
		
		
		let gasOverestimate = Math.floor((estimation * 1.1))
		console.log('gas estimated 10% more: ' + gasOverestimate)
		

		
        let nftTxn = await nftContract.upgradeToken(tokenArray[0], { 
		
		
		gasLimit: gasOverestimate
		
		
		});
		
		
		
		
		
		swal("Upgrading...", "Please wait while your transaction gets confirmed.", "info");


        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
		swal("Upgrade Complete", "You have upgraded your Celestial piece(s). Make sure to refresh the metadata on OpenSea to view your upgraded piece.", "success");
			
		} else {


		
		let estimation = await nftContract.estimateGas.bulkUpgradeTokens(tokenArray)
		
		console.log(estimation)
		
		
		let gasOverestimate = Math.floor((estimation * 1.1))
		console.log('gas estimated 10% more: ' + gasOverestimate)
		

		
        let nftTxn = await nftContract.bulkUpgradeTokens(tokenArray, { 
		
		
		gasLimit: gasOverestimate
		
		
		});
		
		
		
		
		
		swal("Upgrading...", "Please wait while your transaction gets confirmed.", "info");



        await nftTxn.wait();

        console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
		swal("Upgrade Complete", "You have upgraded your Celestial piece(s). Make sure to refresh the metadata on OpenSea to view your upgraded piece.", "success");
		
		}

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
	  if (err.toString().includes('execution reverted')) { swal("Token(s) Invalid", "Try entering the tokens one by one to see which ones are eligible. You most likely do not own the token(s) entered or connected the wrong wallet.", "error"); }
	  if (err.toString().includes('Error: call revert exception')) { swal("Switch Networks", "Switch to Ethereum to be able to interact with the Celestial contract.", "error"); }

	  
    }
  }
  









  
  
  
  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }
  
  
  
  function handleScrollUp() {
	window.scroll({
      top: document.body.scrollTop,
      left: 0, 
      behavior: 'smooth',
    });
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className="connectButton">
        <font color="#870900">•</font> Connect
      </button>
    );
  };


  const connectWalletButton2 = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Wallet
      </button>
    )
  }
  


  
   const upgradeNftButton = () => {
    return (
	  <button onClick={upgradeNftHandler} className='cta-button mint-nft-button'>
        Upgrade Token(s)
      </button>
    )
  } //Mint
  


  const currentWalletConnected = () => {
    return (
      <button id="connected" className="connectButton">
        Wallet Connected
      </button>
    );
  };
  


  


  
  
  
  

  useEffect(() => {
    checkWalletIsConnected();
  }, [])
  
  


  return (
      <div className='main-app'>
	  	<header>
      <div class="dropdown" style={{ float: "right" }}>
        {currentAccount ? currentWalletConnected() : connectWalletButton()}
      </div>
      &nbsp;&nbsp;
      <div class="dropdown" style={{ float: "left" }}>
        <button class="dropbtn">
          ≡ Menu
        </button>
        <div class="dropdown-content">

          <a href="https://nullish.org/celestial">
            ⬑ Nullish.org
          </a>
        </div>
      </div>
	  </header>
	  
	  <br></br>

	  
	  
	  <div class='desc' style={{margin: 'auto'}}>
	  <center><h1>CELESTIAL</h1></center>
	  
	  <br></br>
	  <div class="consoleSubtitle"><b>Project Summary</b></div>
	  <h4 style={{textAlign: 'justify'}}>Celestial is a fully on-chain art collection that has the potential to change with time. After being held for 14 days in the same wallet, it can increase its cluster count with the Upgrade function by the holder. This creates some interesting dynamics when it comes time to transfer the token out of your wallet (sale <b>or</b> transfer).
	  
	  
	  <br></br><br></br>
	  
	  On release day, Distortion holders of multiple tokens were able to combine their 1-to-1 claims get higher tier Celestials. The max supply of the collection effectively dropped by the extra claims sacrificed, leaving less for the public. This is why the Celestial supply is 1064 instead of 1111. Combining 2-4 claims gave a level multiplier of 6N; 5-7 a multiplier of 7N; and 8+ a multiplier of 8N.


	  <br></br><br></br><br></br>
	  <div class="consoleSubtitle"><b>Previews</b></div>
	 

	  The previews are taken from the the final mainnet collection, as minted by some holders of Distortion tokens. Please view on a <b>computer</b> for <b>optimal quality</b>.
	  <br></br><br></br>

	  

	  <div id="prev-row" class="row1" >
		  <div class="prev-img">
			<img src="https://openseauserdata.com/files/1efc48da76f89b6c5dd3b715de8abdb0.svg" width="100%" />
		  </div>
		  <div class="prev-img">
			<img src="https://openseauserdata.com/files/c1d13498a8ff8b5ef73d6346b532457f.svg" width="100%" />
		  </div>
		  <div class="prev-img">
			<img src="https://openseauserdata.com/files/9cae02c331730c9a43432e90db35dc59.svg" width="100%" />
		  </div>
		  <div class="prev-img">
			<img src="https://openseauserdata.com/files/c80f9708aaff027c5291e3f067c97058.svg" width="100%" />
		  </div>
	  </div>

	  
<div class="consoleSubtitle"><b>Attributes</b></div>
	  
	  >> Color
	  <span class="mintFloat">
	  <font color="Cornsilk">1</font>/<font color="Burlywood">2</font>/<font color="Sandybrown">3</font>/<font color="Peru">4</font>/<font color="Saddlebrown">5</font>/<font color="Tan">6</font>/<font color="Goldenrod">7</font>

	  </span>
	  <br></br>
	  
	  >> Cluster Count <span class="mintFloat">Upgradeable</span>
	  <br></br>
	  
	  >> Particle Width <span class="mintFloat">1 - 40</span>
	  <br></br>
	  
	  >> Particle Height <span class="mintFloat">1 - 20</span>
	  
</h4>
	  
	  
	  
	  <br></br><br></br>
	  <br></br><br></br>
	  
	  <div class="consoleSubtitle"><b>Upgrade Tokens</b></div>
      <div>
	  <h4 style={{textAlign: 'justify'}}>
	  You may upgrade a token only once it has been held for 14 days in the same wallet. For example, if you have waited 24 days, the level-up will only be 1 level (be sure to wait 28 to get a full 2 levels).
	  <br></br><br></br>  
	  You can enter one token ID or many, separated by commas.
	  <br></br><br></br>  



	  <center><div id="tokenIdSelectDiv"><input type="text" rows="3" placeholder="Token IDs to upgrade" class="cta-button mint-nft-button" id="tokensToUpgradeId" /></div></center>
	  <br></br>
       {currentAccount ? upgradeNftButton() : connectWalletButton2()}

		</h4>
      </div>

	  <br></br><br></br>
	  <br></br><br></br>	  


	  
        <div class="consoleSubtitle">Community & Links</div>
        <h4 style={{ textAlign: "justify" }}>
          Join the community on Discord to keep in touch with me, as well as
          fellow artists and collectors. Receive updates about current and
          future projects and learn about generative on-chain art. Otherwise,
          follow me on Twitter for regular updates.
          <br />
          <br />
          <div id="prev-row">
            <div class="prev-img">
              <center>
                <a href="https://nulli.sh/discord" target="_blank">
                  <button class="cta-button">Discord</button>
                </a>
              </center>
            </div>
            <div class="prev-img">
              <center>
                <a href="https://twitter.com/null_ish" target="_blank">
                  <button class="cta-button">Twitter</button>
                </a>
              </center>
            </div>
            <div class="prev-img">
              <center>
                <a
                  href="https://opensea.io/collection/celestial-by-nullish"
                  target="_blank"
                >
                  <button class="cta-button">OpenSea</button>
                </a>
              </center>
            </div>
          
          </div>
        </h4>
	  
	  <br></br><br></br>
	  <br></br><br></br>
	  

	  </div> 
	  <br></br>
	  <br></br>
	  <div class="footer">
	  </div>
	  <br></br>
	  

		
    </div>
	

 
	
  )
  
  

  

  
}

export default App;